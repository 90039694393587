.App {
  text-align: center;
  
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  background-color: #dadde7;
  border-radius: 15px;
}
::-webkit-scrollbar-thumb {
  border: 2.5px solid transparent;
  box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.24);
  border-radius: 20px;
}
::-webkit-scrollbar-thumb:active {
  box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.37);
  border-radius: 20px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
html {
  background-image:url('./assets/images/stripes-bg-min.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
}
body {
  background-color: transparent !important;
}
input.Input__input___3e_bf:focus {
  outline: none;
}
.toast {
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 999;
  width: 100%;
  padding: 16px;
  background-color: var(--lightSquidInk);
  font-size: 18px;
  color: #fff;
  text-align: "center";
  display: "block";

}

.Toast__toast___XXr3v {
text-align: center;
display: block;
font-size: 18px;
}
.MuiTableCell-paddingCheckbox{
  width: 100px !important
}
/*.MuiPickersBasePicker-pickerView{
  background: #fff;
}
.MuiDialogActions-spacing{
  background: #2e2e2b;
}*/
/*******/

@font-face {
  font-family: "MuseoSans300";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/MuseoSans_300.ttf");
}
@font-face {
  font-family: "MuseoSans500";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/MuseoSans_500.otf");
}
@font-face {
  font-family: "ProximaBold";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/FontsFree-Net-Proxima-Nova-Bold.otf");
}
@font-face {
  font-family: "AgencyBold";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/AgencyFBBold.ttf");
}
@font-face {
  font-family: "AgencyFB";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/agency-fb.ttf");
}

input[type="date"]::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
}

.bgStyle {
  background: "#181c28" !important;
  padding: 100px;
}
@media only screen and (max-width: 855px) {
  .bgStyle {
    padding: 80px 10px;
  }
}
a:visited {
  color: #BF40BF;
}
